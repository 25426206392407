<template>
    <v-row align="start" class="mt-2" dense justify="start" no-gutters>
        <v-btn color="primary" text @click="addPage">
            <v-icon left>mdi-note-plus-outline</v-icon>
            add page
        </v-btn>
    </v-row>
</template>

<script>
import {INSERT_INSPECTION_FORM_PAGE} from "@/modules/app/store/mutation-types";

export default {
    name: "KurccAddPageButton",
    methods: {
        addPage() {
            this.$store.commit(INSERT_INSPECTION_FORM_PAGE)
        }
    }
}
</script>